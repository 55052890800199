body {
  margin: 0;
  padding: 0;
  background-color: #292f37;
}
* {
  font-family: "Arial";
}

.app {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.main {
  display: flex;
  align-items: flex-start;
  border-radius: 0 10px 10px 10px;
  box-shadow: 0 0 100px rgba(0, 0, 0, 1);
  padding: 50px;
  background-color: #fff;
  transition: all 1s ease;
  @media screen and (min-width: 1024px) {
    min-width: 900px;
  }
}

.react-tabs {
  margin-top: 10vh;
  .react-tabs {
    &__tab-list {
      margin-bottom: 0;
      border: none;
    }
    &__tab {
      border: none;
      color: #fff;
      &--selected {
        color: #292f37;
        background-color: #fff;
      }
    }
  }
}
