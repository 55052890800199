.data-box {
  margin-right: 50px;
}
.title {
  font-size: 20px;
}
.form {
  width: 300px;
  box-sizing: border-box;
}
.input {
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px;
  height: 40px;
  width: 100%;
  max-width: 100%;
  &.textarea {
    min-height: 80px;
  }
}
.remove-btn {
  width: 20px;
  height: 20px;
  background-color: #292f37;
  color: #fff;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
}
.submit {
  width: 100%;
  height: 40px;
  background-color: #292f37;
  color: #fff;
  border: none;
  cursor: pointer;
}
.box {
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #767676;
  position: relative;
}

.radio-group {
  display: flex;
  input {
    margin-right: 16px;
  }
}
